import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FAQContainer, FAQs } from '../components/FAQs'

import { Box, Flex } from 'grid-styled'
import CTA from '../components/CTA'
import GalleryContainer from '../components/GalleryContainer'
import GalleryImage from '../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info,
} from '.'
import Form from '../components/Form'
import { Text, GallerySection, Content } from '../components/styles'
import Button from '../components/Button'
import ContentCard from '../components/ContentCard'
import Hero from '../components/Hero'
import { Video } from '../components/procedures'

export default ({ data }) => (
  <Layout header="light">
    <Helmet>
      <title>Local Sports Aviation Clubs - QLD Sports Aviators</title>
      <meta
        name="description"
        content="Join your local paragliding, hang gliding, microlighting or
        multidisciplinary club."
      />
    </Helmet>

    <HomeHeader slant={false}>
      <figure>
        <Img fluid={data.wing.childImageSharp.fluid} />
      </figure>
      <div>
        <h2>Local Clubs</h2>
        <Text size="large">
          Join your local paragliding, hang gliding, microlighting or
          multidisciplinary club.
        </Text>
      </div>
    </HomeHeader>

    <FAQContainer style={{ marginTop: -65 }}>
      <FAQs>
        <h4 style={{ color: '#c12422' }}>Sunshine Coast Sports Aviators</h4>
        <p>
          The Sunshine Coast Sports Aviators Club (SCSA), caters for all forms
          of paragliding, hang gliding, and microloghting sports aviation
          disciplines. SCSA manages the flying operations at a range of costal
          and hinterland sites from Gympie and Rainbow Beach, down to Woody
          Point in Redcliffe Peninsula.
        </p>
        <a href="https://sunshinecoastsportsaviators.com.au">
          https://sunshinecoastsportsaviators.com.au
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>Canungra Hang Gliding Club</h4>
        <p>
          Canungra is one of Australias most succesful clubs. They are a
          progressive association of sports aviators dedicated to the
          advancement of free flight in Canungra region.
        </p>
        <a href="https://www.flycanungra.com.au">
          https://www.flycanungra.com.au
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>Toowoomba Hang Gliding Club</h4>
        <p>
          The Wicked Wings Club is an active group of recreational pilots from
          paragliding and hang gliding aviation disciplines. We love nothing
          better than to spend the day flying with fellow pilots around the
          ridges, we might even hook the odd thermal and indulge in a little
          cross country jaunt. At the end of the day there is always time for
          some post flight bragging and a few refreshments at the landing zone.
        </p>
        <a href="http://www.wickedwings.com.au">
          http://www.wickedwings.com.au
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>
          Paradise Flyers Powered Paraglider Club
        </h4>
        <p>
          Welcome to Paradise Flyers, a powered paraglider club located in one
          the of the most beautiful places on earth. We want to encourage any
          pilots in the area to join the club and see the remarkable flying
          opportunities in this beautiful world of ours.
        </p>
        <a href="https://paradiseflyers.org.au">
          https://paradiseflyers.org.au
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>Surf & Turf Flyers</h4>
        <p>
          We are an inclusive sports aviation club who works to make flying an
          enjoyable experience for all our members.
        </p>
        <h4 style={{ color: '#c12422' }}>Dalby Hang Gliding Club</h4>
        <p>
          The Dalby Hang Gliding Club operates from the Dalby Aerodrome, a small
          airport located 1.2 km north of the town of Dalby. Also the home of
          one of Australia's leading hang gliding competitions, The Dalby Big
          Air.
        </p>
      </FAQs>
    </FAQContainer>

    <Hero bgImage={data.blurredHero.childImageSharp} height="550px">
      <h3>Want to find our more?</h3>
      <p>
        Complete the enquiry form and contact us today to find out how we can
        help.
      </p>
      <Flex>
        <Button color={'#fff'} small={true} outline={true}>
          Contact Us
        </Button>
      </Flex>
    </Hero>
  </Layout>
)

export const query = graphql`
  query ClubsQuery {
    paragliding: file(relativePath: { eq: "paragliding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hanggliding: file(relativePath: { eq: "hang-gliding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    microlighting: file(relativePath: { eq: "microlighting.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wing: file(relativePath: { eq: "paraglide-club.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offField: file(relativePath: { eq: "gallery/carports/gable/cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    patiosBrisbane: file(
      relativePath: { eq: "gallery/carports/skillion/cover.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    training: file(relativePath: { eq: "gallery/carports/flyover/cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    accessories: file(relativePath: { eq: "roofing-brisbane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
